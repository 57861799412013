<div class="container">

    <div class="m-v-20 text-center">
        <img alt="" src="assets/images/logo/logo-text-only.svg" class="main-logo">
    </div>


    <div class="main-content p-t-10">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
</div>



